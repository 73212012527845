// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$text-color:            #8c8c8c;
$body-bg-color:			#f0f0f0;
$main-bg-color:			#e7e7e9;
$login-error-color:		#ee4896;

$brand-primary:         #944d91;
$brand-secondary:       #2b3990;
$brand-tertiary:        #944d91;

$nav-text-color:		$brand-secondary;
$nav-bg-color:			#d0d0d0;
$nav-text-color-active:	white;
$nav-bg-color-active:	$brand-secondary;


// Fonts
$font-family-sans-serif: Verdana, Geneva, "DejaVu Sans", sans-serif !default;



// Mixins
@mixin woocommerce-error() {
	position: relative;
	width: auto;
	margin: 0 0 2em !important;
	padding: 1em 2em 1em 3.5em !important;
	border-top: 3px solid $brand-primary;
	color: $text-color;
	background-color: lighten($body-bg-color,5%);
	list-style: none outside !important;
	&:before {
		content: "\e028";
		position: absolute;
		top: 1em;
		left: 1.5em;
		font-family: "WooCommerce";
		display: inline-block;
	}
	.button {
		float: right;
	}
	li {
		margin-left: 0 !important;
		padding-left: 0 !important;
		list-style: none outside !important;
	}
}
@mixin woocommerce-error-success() {
	border-top-color: #8fae1b;
	&:before {
		content: "\e015";
		color: #8fae1b;
	}
}
@mixin woocommerce-error-info() {
	border-top-color: #1e85be;
	&:before {
		color: #1e85be;
	}
}
@mixin woocommerce-error-danger() {
	border-top-color: #b81c23;
	&:before {
		content: "\e016";
		color: #b81c23;
	}
}

