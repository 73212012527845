.banner {
	border: 0;
	background-color: white;
}
	.navbar-header {
		
	}
		.navbar-brand {
			height: auto;
		}
	.navbar-collapse {
		border: 0;
		.navbar-nav {
			margin-top: 5px;
			margin-bottom: 0;
			> li {
				> a {
					margin-bottom: 5px;
					color: $nav-text-color;
					background-color: $nav-bg-color;
					font-size: 16px;
					&:hover,
					&:focus {
						color: $nav-text-color;
						background-color: $nav-bg-color;
						text-decoration: underline;
					}
				}
			}
			.active {
				> a {
					color: $nav-text-color-active;
					background-color: $nav-bg-color-active;
					&:hover,
					&:focus {
						color: $nav-text-color-active;
						background-color: $nav-bg-color-active;
					}
				}
			}
		}
	}

@media ( min-width: $screen-sm-min ) {
	.navbar-collapse {
		text-align: right;
		.navbar-nav {
			margin-top: 115px;
			float: none;
			> li {
				display: inline-block;
				float: none;
				> a {
					height: 35px;
					margin-bottom: 0;
					margin-right: 6px;
					padding: 0 25px;
					font-size: 16px;
					line-height: 35px;
				}
				&:last-child > a {
					margin-right: 10px;
				}
				&.menu-sign-in > a {
					position: relative;
					&:after {
						content: "";
						position: absolute;
						bottom: -40px;
						left: 0;
						width: 100%;
						height: 40px;
						background-color: $nav-bg-color-active;
					}
				}
			}
		}
	}
}
/*
.banner {
	height: 160px;
	border: 0;
	background-color: white;
}

	.navbar-collapse {
		text-align: right;
		.navbar-nav {
			float: none;
			> li {
				display: inline-block;
				float: none;
				> a {
					height: 35px;
					margin-left: 5px;
					padding: 0 25px;
					color: $nav-text-color;
					background-color: $nav-bg-color;
					font-size: 16px;
					line-height: 35px;
					&:hover,
					&:focus {
						color: $nav-text-color;
						background-color: $nav-bg-color;
						text-decoration: underline;
					}
				}
			}
			.active > a {
				color: $nav-text-color-active;
				background-color: $nav-bg-color-active;
					&:hover,
					&:focus {
						color: $nav-text-color-active;
						background-color: $nav-bg-color-active;
						text-decoration: none;
					}
			}
		}
	}
*/


