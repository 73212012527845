/** 
 * Login
 */

form.login {
	label {
		font-weight: 300;
	}
}



/** 
 * Login Widget
 */

.widget_login_widget {
	padding: 20px;
	border: 10px solid white;
	background-color: $brand-secondary;
	box-shadow: 0px 5px 5px rgba( 0, 0, 0, 0.1 );
	label {
		color: white;
	}
	.lost_password {
		a {
			color: white;
		}
	}
	.woocommerce-message {
		@include woocommerce-error();
		@include woocommerce-error-info();
		padding: 1em !important;
		&:before {
			display: none;
		}
	}
	.woocommerce-error {
		@include woocommerce-error();
		@include woocommerce-error-danger();
		padding: 1em !important;
		&:before {
			display: none;
		}
	}
	input[type="submit"] {
		display: none;
	}
}
@media ( min-width: $screen-sm-min ) {
.widget_login_widget {
	min-height: 320px;
	margin-left: -10px;
	border-left: 0;
}
}



/** 
 * My Account
 */

.my-account {
	.main {
		form.login {
			padding: 0;
			border: 0;
		}
	}
}



/** 
 * Gravity Forms
 */

.gform_wrapper {
    ul {
        @extend .list-unstyled;
    }
    li {
        @extend .form-group;
    }
    form {
        margin-bottom: 0;
    }
    .gfield_required {
        padding-left: 1px;
        color: $state-danger-text;
    }
}

.ginput_container {
    input,
    select,
    textarea {
        @extend .form-control;
    }
    textarea {
      height: auto;
    }
}

.ginput_complex {
	&.no_prefix,
	&.has_first_name,
	&.no_middle_name,
	&.has_last_name,
	&.no_suffix {
		.name_first {
			width: 47%;
			margin-right: 6%;
			display: block;
			float: left;
			label {
				font-weight: normal;
			}
		}
		.name_last {
			width: 47%;
			display: block;
			float: left;
			label {
				font-weight: normal;
			}
		}
	}
}

.gform_button {
  @extend .btn;
  @extend .btn-primary;
}

.gform_wrapper .gfield_error {
    .gfield_label {
        color: $state-danger-text;
    }
    input,
    select,
    textarea {
        border-color: $alert-danger-border;
        background-color: $alert-danger-bg;
        color: $alert-danger-text;
        @include form-control-focus( $alert-danger-text );
    }
}

.validation_message {
    color: $brand-danger;
}

.validation_error {
    @extend .alert;
    @extend .alert-danger;
}

#gforms_confirmation_message {
    @extend .alert;
    @extend .alert-success;
}



/** 
 * WooCommerce
 */

.woocommerce .col2-set .col-1,
.woocommerce-page .col2-set .col-1 {
	width: 100%;
	float: none;
}

.woocommerce-checkout {
	.form-row {
		@extend .form-group;
	}
		.input-text  {
			@extend .form-control;
		}
		.required {
			border: 0;
		}
}

.woocommerce-FormRow {
	@extend .form-group;
}
	.woocommerce-Input--text {
		@extend .form-control;
	}
.woocommerce input.button,
.woocommerce-Button {
	@extend .btn;
	@extend .btn-primary;
}




