html {
	background-color: $body-bg-color;
}
body {
	background: $body-bg-color url(../images/bg.png) repeat-x center top;
}
h1,
h2,
h2,
h4,
h5,
h6 {
	margin-top: 10px;
	color: $brand-secondary;
	font-size: 28px;
}
ul {
	padding-left: 20px;
}

// Video.js
.video-js {
	padding-top: 65.17%;
}
.vjs-fullscreen {
	padding-top: 0px;
}

// Main / sidebar
.main {
	margin-bottom: 20px;
	.inner {
		min-height: 320px;
		padding: 20px;
		border: 10px solid white;
		background-color: $main-bg-color;
		box-shadow: 0px 5px 5px rgba( 0, 0, 0, 0.1 );
	}
}
.logged-in .main,
.sidebar-primary.logged-in .main {
	width: 100%;
	.woocommerce-message {
		@include woocommerce-error();
		@include woocommerce-error-success();
	}
}
@media ( min-width: $screen-sm-min ) {
.main {
	.inner {
		margin-right: -20px;
	}
}
.my-account .main,
.basket .main,
.checkout .main {
	.inner {
		margin-right: 0;
	}
}
.logged-in .main,
.sidebar-primary.logged-in .main {
	.inner {
		margin-right: 0;
	}
}
}
.privacy-policy .main ,
.terms-of-use .main {
	h2 {
		color: $text-color;
		font-weight: bold;
		font-size: 14px;
	}
}
.sidebar {
	margin-bottom: 20px;
}
.logged-in .sidebar {
	display: none;
}

.page-header {
	margin: 0;
	padding: 0;
	border: 0;
}

// Boxouts
.boxouts {
	margin-bottom: 20px;
	padding: 10px 20px;
	background-color: white;
	box-shadow: 0px 5px 5px rgba( 0, 0, 0, 0.1 );
	.inner {
		@include make-row();
	}
		.boxout {
			@include make-sm-column( 4 );
			padding-bottom: 20px;
			img {
				max-width: 100%;
			}
		}
			h2 {
				padding-bottom: 5px;
				border-bottom: 2px solid #91478f;
				color: $text-color;
				font-size: 20px;
				text-align: center;
			}
		.boxout-2 {
			text-align: center;
			h2 {
				border-bottom-color: #e63d96;
			}
			p {
				font-size: 18px;
			}
		}
			.price {
				font-size: 20px;
				text-align: center;
			}
		.boxout-3 {
			text-align: center;
		}
}
@media ( min-width: $screen-sm-min ) {
.boxouts {
		.boxout {
			padding-bottom: 0;
		}
}
}

// Currency switcher widget.
.widget_wc_aelia_currencyswitcher_widget {
	margin-bottom: $line-height-computed;
	color: black;
	.currency_switch_form { // form
		display: inline;
	}
		#aelia_cs_currencies { // select
			width: 100%;
			margin-top: 5px;
			margin-left: 5px;
			font-size: $font-size-large;
		}
		.change_currency { // button

		}
}

// Checkout select field.
.wchau-source {
	select {
		@extend .form-control;
	}
}
.woocommerce,
.woocommerce-page  {
	.col2-set#customer_details {
		.col-2 {
			width: 100%;
			float: none;
		}
	}
}

// Single Course
.single-course .course .course-modules .table th.expand,
.single-course .course .course-modules .table td.expand,
.single-course .course .course-modules .table td.units-inset {
	display: none;
}
.single-course .course .course-modules .table .units {
	display: table-cell;
}


// Single Unit
#joltlms-sco {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #e1e1e1;
	// background: $body-bg-color url(../images/bg.png) repeat-x center top;
}
.admin-bar #joltlms-sco {
	padding-top: 32px;
}



















