.content-info {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: white;
	text-align: center;
}
	.copyright {
		font-size: 11px;
	}
	.secondary-navigation {
		.menu {
			margin: 20px 0;
			padding-left: 0;
			font-size: 11px;
			list-style: none;
			> li {
				> a {
					color: $text-color;
					font-weight: 700;
				}
			}
		}
	}
	.logos {
		text-align: center;
	}
@media ( min-width: $screen-sm-min ) {
.content-info {
	text-align: left;
}
	.copyright {
		font-size: 11px;
	}
	.secondary-navigation {
		text-align: center;
		.menu {
			margin: 0;
			float: none;
			> li {
				display: inline-block;
				> a {
					margin-right: 3px;
					padding-right: 8px;
					border-right: 1px solid $text-color;
				}
				&:last-child > a {
					margin-right: 0;
					padding-right: 0;
					border-right: 0;
				}
			}
		}
	}
	.logos {
		text-align: right;
	}
}